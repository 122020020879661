<template>
  <section v-if="!this.isLoading">
    <v-tabs fixed-tabs background-color="indigo" color="white" v-model="modelTab">
      <v-tab
        :to="{
          name: 'tratamientoTAR',
          params: { id: this.$route.params['id'] },
        }"
      >
        Tratamiento TAR
      </v-tab>
      <v-tab
        :exact="true"
        :to="{
          name: 'tratamientoPOP',
          params: { id: this.$route.params['id'] },
        }"
      >
        Tratamiento POP
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </section>
  <section v-else>
    <v-row>
      <v-col align-self="center">
        <v-progress-circular indeterminate :size="50" color="primary" align></v-progress-circular>

        <h3>Cargando...</h3>
      </v-col>
    </v-row>
  </section>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      modelTab: false,
    };
  },
  methods: {
    async loadPAC() {
      await this.$store.dispatch("patients/loadPAC", {
        idPatient: this.patientVariables.rut,
      });
    },
     async loadVariables() {
      await this.$store.dispatch("patients/loadVariablesData", {
        idPatient: this.$route.params["id"],
      });
    },
  },
  computed:{
    patientVariables() {
      return this.$store.getters["patients/patientVariables"];
    },
  },
  async created() {
    await this.loadPAC();
    await this.loadVariables();
  },
};
</script>
