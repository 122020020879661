var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"container-safe-area"},[_c('v-row',[_c('v-col',[_c('v-bottom-navigation',{attrs:{"color":"primary","horizontal":"","grow":""}},[_c('v-btn',{attrs:{"exact":true,"to":{
              name: 'paciente',
              params: { id: this.id },
            }}},[_c('span',[_vm._v(" Paciente "+_vm._s(_vm.id)+" ")]),_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-btn',{attrs:{"to":{
              name: 'pacienteLaboratorio',
              params: { id: this.id },
            }}},[_c('span',[_vm._v(" Laboratorio")]),_c('v-icon',[_vm._v("mdi-eyedropper")])],1),_c('v-btn',{attrs:{"to":{
              name: 'pacienteTratamiento',
              params: { id: this.id },
            }}},[_c('span',[_vm._v(" Tratamientos")]),_c('v-icon',[_vm._v("mdi-pill-multiple")])],1),_c('v-btn',{attrs:{"to":{
              name: 'pacienteFastTrack',
              params: { id: this.id },
            }}},[_c('span',[_vm._v(" Fast Track")]),_c('v-icon',[_vm._v("mdi-clock-fast")])],1),_c('v-btn',{attrs:{"exact":true,"to":{
              name: 'subPredictores',
              params: { id: this.id },
            }}},[_c('span',[_vm._v(" Predictores")]),_c('v-icon',[_vm._v("mdi-charity")])],1)],1),(this.$route['name'] != 'paciente')?_c('router-view'):_c('perfil-paciente',{attrs:{"id":_vm.id}})],1)],1),(this.$route['name'] === 'paciente')?_c('v-row',{staticClass:"justify-center"},[(this.predictions.length>0)?_c('v-col',[_c('v-card',{staticClass:"mt-5 px-5"},[_c('v-row',[_c('v-col',[_c('v-card-text',[_c('h1',[_vm._v("Nivel de adherencia anual")])])],1)],1),_vm._v(" "+_vm._s(_vm.predictionFarmaciaData.valores)),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ApexChart',{attrs:{"height":"300","width":"100%","type":"line","options":_vm.options,"series":_vm.series}})],1)],1)],1)],1):_c('v-col',[_c('v-row',[_c('v-col',[_c('v-card-text',[_c('h1',[_vm._v("No hay datos de adherencia para este paciente")])])],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }