export default{
    registerNewPatient(state,payload){
        state.patients.push(payload)
    },
    setPatients(state,payload){
        state.patients = payload
    },
    setPatient(state,payload){
        state.patient=payload
    },
    addEncuestaFast(state,payload){
        state.patient = {...state.patient, "encuesta":payload}
    },
    setEncuestaPatient(state,payload){
        state.patientEncuesta = payload
    },
    setVariable(state,payload){
        state.patientVariables = payload
    },
    setCD4(state,payload){
        state.patientCD4 = payload
    },
    setRNA(state,payload){
        state.patientRNA = payload
    },
    setAuditorias(state,payload){
        state.patientAuditorias = payload
    },
    setPacienteIntervencional(state,payload){
        state.pacienteIntervencional = payload
    },
    setTAR(state,payload){
        state.patientTAR = payload
    },
    setPAC(state,payload){
        state.patientPAC = payload
    },
    setPOP(state,payload){
        state.patientPOP = payload
    },
    setDrop(state,payload){
        state.patientDrop = payload
    },
    setTerapiaPAC(state,payload){
        state.patientTerapiaPAC = payload
    },
    setPredictions(state,payload){
        state.predictions = payload
    },
    setTabVariables(state,payload){
        state.tabStatus.variables = payload
    },
    setTabPrediction(state,payload){
        state.tabStatus.prediccion = payload
    },
    setTabModelo(state,payload){
        state.tabStatus.modelo = payload
    },
    setStatusForm(state,payload){
        console.log(payload)
        state.statusForm = payload
    }
}