<template>
  <v-app>
    <v-container class="container-safe-area">
      <section>
        <v-form @submit.prevent="saveData" ref="form">
          <v-row
            ><v-col>
              <v-subheader> <strong> Mecanismo de infección </strong> </v-subheader
              ><v-select
                v-model="patientVariables.mode"
                label="Mode"
                :items="items_mode_2"
                item-text="descripcion"
                item-value="mode"
                return-object
                single-line
                required
                :rules="[(v) => !!v || 'Falta modo de infección']"
              >
              </v-select></v-col
            ><!-- <v-col
            ><v-subheader> <strong> Modo de transmisión (mode_oth)</strong> </v-subheader
            ><v-select
              v-model="patientVariables.mode_oth"
              label="Mode_oth"
              :items="items_mode_oth"
              item-text="traduccion"
              item-value="original"
              return-object
              single-line
              required
              :rules="[(v) => !!v || 'Falta modo de transmisión']"
            >
            </v-select></v-col
        > --></v-row
          >
          <v-subheader>
            <strong> Etapa CDC (cdc_stage)</strong>
          </v-subheader>
          <v-select
            v-model="patientVariables.cdcstage"
            label="Cdcstage"
            :items="items_cdc"
            return-object
            single-line
            required
            :rules="[(v) => !!v || 'Falta modo cdc stage']"
          >
          </v-select>
          <v-radio-group v-model="patientVariables.aids_y" required :rules="[(v) => !!v || 'Falta aids']">
            <template v-slot:label>
              <strong>¿Está en etapa de sida?</strong>
            </template>
            <v-radio label="Si" value="1.0"></v-radio>
            <v-radio label="No" value="2.0"></v-radio>
            <v-radio label="Desconocido" value="9.0"></v-radio>
          </v-radio-group>
          <v-row
            ><v-col
              ><v-radio-group
                class="modify-input"
                v-model="patientVariables.Genero"
                required
                :rules="[(v) => !!v || 'Falta identidad de genero']"
              >
                <template v-slot:label>
                  <strong>Identidad de Genero</strong>
                </template>
                <v-radio label="Cis" value="Cis"></v-radio>
                <v-radio label="Trans" value="Trans"></v-radio>
                <v-radio label="Otro" value="Otro"></v-radio>
                <v-radio label="No binario" value="Otro"></v-radio>
                <v-radio label="Sexo fluido" value="Otro"></v-radio> </v-radio-group></v-col
            ><v-col>
              <v-radio-group v-model="patientVariables.sex" required :rules="[(v) => !!v || 'Falta sexo']">
                <template v-slot:label>
                  <strong>Sexo</strong>
                </template>
                <v-radio v-for="n in ['F', 'M', 'T']" :key="n" :label="`${n}`" :value="n">
                </v-radio> </v-radio-group></v-col
          ></v-row>

          <v-row
            ><!-- <v-col>
            <v-select
              v-model="patientVariables.ADQUISICION"
              required
              :rules="[(v) => !!v || 'Falta adquisición']"
              label="Adquisicion"
              :items="[
                //'IVDU',
                //'NS/NR',
                //'Ocupacional',
                'SEXUAL',
                'SIN DATO',
              ]"
            ></v-select>
          </v-col> -->
            <v-col>
              <v-select
                required
                :rules="[(v) => !!v || 'Falta orientación sexual']"
                v-model="patientVariables.CONDUSEXUAL"
                label="Orientación Sexual"
                :items="['BISEXUAL', 'HETEROSEXUAL', 'HOMOSEXUAL', 'SIN DATO']"
              ></v-select>
            </v-col>
          </v-row>

          <v-radio-group v-model="patientVariables.Derivar" required :rules="[(v) => !!v || 'Falta derivar']"
            ><template v-slot:label>
              <strong>¿Paciente derivado desde otro centro?</strong>
            </template>
            <v-radio label="No" value="No deriva"></v-radio>
            <v-radio label="Si" value="True"></v-radio>
            <!-- <v-radio v-for="n in ['No deriva', 'True',]" :key="n" :label="`${n}`" :value="n"></v-radio
        > --></v-radio-group
          >
          <v-radio-group v-model="patientVariables.hla" required :rules="[(v) => !!v || 'Falta resultado hla']">
            <template v-slot:label>
              <strong>Resultado prueba Hla</strong>
            </template>
            <v-radio v-for="n in ['Negativo', 'Positivo', 'Desconocido']" :key="n" :label="`${n}`" :value="n"></v-radio
          ></v-radio-group>

          <v-subheader>
            <strong> Número de Enfermedades Oportunistas (POP)</strong>
          </v-subheader>
          <v-text-field
            required
            :rules="[(v) => !!v || 'Falta cantidad de enfermedades oportunistas']"
            v-model="patientVariables.pop_ingreso"
            label="Pop"
            hide-details
            single-line
            type="number"
          >
          </v-text-field>
          <v-subheader>
            <strong> Nacimiento</strong>
          </v-subheader>
          <v-row>
            <v-col>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    required
                    :rules="[(v) => !!v || 'Falta fecha de nacimiento']"
                    v-model="patientVariables.birth_d"
                    label="Fecha de nacimiento"
                    prepend-icon="mdi-calendar"
                    messages="Apretar en el año para ir a un año en especifico, lo mismo para mes"
                    readonly
                    no-title
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="patientVariables.birth_d"
                  v.model:active-picker="activePicker"
                  :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="save('menu')"
                  color="indigo darken-2"
                >
                </v-date-picker
              ></v-menu>
            </v-col>
            <v-col>
              <v-subheader>
                <strong> Edad: </strong>
                {{ "  " + patientVariables.edad + " años" }}
              </v-subheader>
            </v-col>
            <v-col>
              <v-subheader>
                <strong> Segmento etario: </strong>
                {{ patientVariables.Segmento_etario }}
              </v-subheader>
            </v-col>
          </v-row>
          <!-- <h3>Mediciones CD4</h3> -->

          <v-row>
            <v-col
              ><div data-app>
                <v-menu
                  ref="menu_cd4"
                  v-model="menu_cd4"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      required
                      :rules="[(v) => !!v || 'Falta fecha de cd4']"
                      v-model="cd4_d"
                      label="Fecha registro cd4"
                      prepend-icon="mdi-calendar"
                      messages="Apretar en el año para ir a un año en especifico, lo mismo para mes"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    required
                    :rules="[(v) => !!v || 'Falta fecha de cd4']"
                    v-model="cd4_d"
                    v.model:active-picker="activePicker"
                    no-title
                    :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="save('menu_cd4')"
                    color="indigo darken-2"
                  >
                  </v-date-picker
                ></v-menu></div
            ></v-col>
            <v-col>
              <v-text-field
                required
                :rules="[(v) => !!v || 'Falta medición de cd4']"
                v-model="cd4_v"
                label="Medición CD4"
                hide-details
                single-line
                type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- <v-radio-group
          v-model="patientVariables.Condicion_especial"
          required
          :rules="[(v) => !!v || 'Falta condición especial']"
        >
          <template v-slot:label>
            <strong>Condición especial</strong>
          </template>
          <v-radio
            v-for="n in [
              'No',
              'PROTOCOLO',
              //'Dipreca',
              //'Embarazada',
              //'Hospitalizada',
              //'PPL',
            ]"
            :key="n"
            :label="`${n}`"
            :value="n"
          ></v-radio>
        </v-radio-group> -->
          <!-- <h2>Subpredictor Ecosistema</h2> -->
          <v-subheader>
            <strong> Previsión</strong>
          </v-subheader>
          <v-select
            required
            :rules="[(v) => !!v || 'Falta fecha previsión de salud']"
            v-model="patientVariables.Prevision"
            :items="items_prevision"
            label="Prevision de salud"
            return-object
            single-line
          >
          </v-select>

          <v-subheader>
            <strong>Situación Laboral</strong>
          </v-subheader>
          <v-select
            required
            :rules="[(v) => !!v || 'Falta situación laboral']"
            v-model="patientVariables.employment_description"
            :items="items_laboral"
            label="Situación Laboral"
            return-object
            single-line
          >
          </v-select>
          <v-row>
            <v-col>
              <v-subheader>
                <strong>Nivel educacional</strong>
              </v-subheader>
              <v-select
                required
                :rules="[(v) => !!v || 'Falta nivel educacional']"
                v-model="patientVariables.nivel_educacional"
                :hint="`${patientVariables.nivel_educacional}`"
                :items="items_educacion"
                item-text="nombre"
                item-value="nombre"
                label="Nivel educacional"
                return-object
                single-line
              ></v-select>
            </v-col>
          </v-row>
          <!-- <h2>Subpredictor alcohol y otras drogas</h2> -->
          <v-menu
            ref="menu_ingreso_fa"
            v-model="menu_ingreso_fa"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                required
                :rules="[(v) => !!v || 'Falta fecha de ingreso a la fundación']"
                v-model="patientVariables.Fecha_Ingreso_FA"
                label="Fecha de ingreso a la fundación"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="patientVariables.Fecha_Ingreso_FA"
              v.model:active-picker="activePicker"
              :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="save('menu_ingreso_fa')"
              color="indigo darken-2"
            >
            </v-date-picker
          ></v-menu>

          <v-row>
            <v-col>
              <v-subheader><strong>Seleccionar Region</strong></v-subheader>
              <v-select
                required
                :rules="[(v) => !!v || 'Falta comuna']"
                v-model="patientVariables.Region"
                :items="Object.keys(this.comunaRegiones).sort()"
                item-text="nombre"
                item-value="nombre"
                label="Region"
                return-object
                single-line
              ></v-select>
            </v-col>
            <v-col>
              <v-subheader>
                <strong>Seleccionar comuna</strong>
              </v-subheader>
              <v-select
                required
                :rules="[(v) => !!v || 'Falta comuna']"
                v-model="patientVariables.Comuna"
                :items="
                  patientVariables.Region
                    ? Object.values(this.comuna(patientVariables.Region)).sort()
                    : ['Elija Region primero']
                "
                item-text="nombre"
                item-value="nombre"
                label="Comuna"
                return-object
                single-line
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientVariables.Tiene_Autorizado"
                required
                :rules="[(v) => !!v || 'Falta autorización']"
              >
                <template v-slot:label>
                  <strong>¿Tiene tutor que retire sus fármacos?</strong>
                </template>
                <v-radio v-for="n in ['Verdadero', 'Falso', 'Sin dato']" :key="n" :label="`${n}`" :value="n"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <!--  <v-col>
          <h3>Segmento comuna</h3>
          <p>{{ patientVariables.segmento_comuna }}</p>
        </v-col> -->

          <!-- <h2>Subpredictor depresión</h2> -->
          <!-- <v-subheader>
          <strong>Policlinico</strong>
        </v-subheader>
        <v-select
          required
          :rules="[(v) => !!v || 'Falta policlinico']"
          v-model="patientVariables.Policlinico"
          label="Policlinico"
          :items="['PVVIH', 'Chagas']"
          return-object
          single-line
        >
        </v-select> -->
          <!-- <v-radio-group
          required
          :rules="[(v) => !!v || 'Falta Hospitalizado']"
          v-model="patientVariables.rp_Hospitalizado"
        > -->
          <!-- <template v-slot:label>
            <strong>Rp Hospitalizado</strong>
          </template>
          <v-radio
            v-for="n in [
              //'Verdadero',
              'False',
            ]"
            :key="n"
            :label="`${n}`"
            :value="n"
          ></v-radio>
        </v-radio-group> -->
          <v-btn
            :exact="true"
            :to="{
              name: 'pacienteFastTrack',
              params: { id: this.$route.params['id'] },
            }"
            >Atras</v-btn
          >
          <v-btn type="submit">Guardar datos</v-btn>
        </v-form>
        <v-snackbar v-model="snackbar" :multi-line="multiLine" :color="snackbar_color">
          <ul v-if="errores">
            <li v-for="(value, name) in errores" :key="value.id">{{ name }}: {{ value[0] }}</li>
          </ul>
          <span v-else>{{ mensaje }}</span>

          <template v-slot:action="{ attrs }">
            <v-btn color="black" text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
          </template>
        </v-snackbar>
      </section>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      errores: null,
      multiLine: true,
      rut: null,
      cd4_d: null,
      cd4_v: null,
      mensaje: null,
      snackbar: false,
      snackbar_color: null,
      menu_ingreso_fa: false,
      menu_cd4: false,
      menu: false,
      items_laboral: ["SIN DATO", "ACTIVO", "PASIVO (estudiante que no trabaja y jubilados)", "CESANTE"],
      items_prevision: [
        "Fonasa A",
        "Fonasa B",
        "Fonasa C",
        "Fonasa D",
        "Prais",
        "Prais/A",
        "Prais/B",
        "Prais/D",
        "Dipreca",
        "Bloqueado",
        "Isapre",
      ],
      items_educacion: ["UNIVERSITARIA", "TECNICO", "MEDIA", "BASICA", "SIN DATO"],
    };
  },
  methods: {
    save(calendar, date) {
      this.$refs[calendar].save(date);
    },
    async loadCD4() {
      await this.$store.dispatch("patients/loadCD4Data", {
        idPatient: this.rut,
      });
    },
    async saveData() {
      const valid = this.$refs.form.validate();

      if (valid) {
        await this.$store
          .dispatch("patients/addVariablesFast", {
            dataPatient: {
              ...this.patientVariables,
              mode: this.patientVariables.mode.mode,
              mode_oth: this.patientVariables.mode_oth.original,
              Region: this.patientVariables.Region,
              Policlinico: "PVVIH",
              Condicion_especial: "No",
              rp_Hospitalizado: "FALSO",
            },
            idPatient: this.rut,
          })
          .then((response) => {
            if (response.status == 400) {
              this.enabled = false;
              this.snackbar_color = "error";
              this.snackbar = true;
              this.errores = response.data;
            } else if (response.status == 200) {
              this.$store.dispatch("patients/tabStatusAction", "prediction");
              this.$router
                .push({
                  name: "pacienteFastTrackPrediction",
                  params: { id: this.$route.params["id"] },
                })
                .catch((error) => console.log(error));
            }
          });
        if (this.$store.getters["patients/patientCD4"].length > 0) {
          const cd4_values = this.$store.getters["patients/patientCD4"][0];
          this.$store.dispatch("patients/editCD4", { ...cd4_values, cd4_d: this.cd4_d, cd4_v: this.cd4_v });
        } else if (this.$store.getters["patients/patientCD4"].length === 0) {
          this.$store.dispatch("patients/addCD4", {
            cd4_d: this.cd4_d,
            cd4_v: this.cd4_v,
            paciente: this.patientVariables.rut,
          });
        }
        this.loadCD4();

        window.scrollTo(0, 0);
      } else {
        this.snackbar_color = "error";
        this.snackbar = true;
        this.mensaje = "Faltan campos";
      }
    },
    comuna(comuna) {
      try {
        return Object.values(this.comunaRegiones[comuna]);
      } catch {
        return "Otra";
      }
    },
  },
  computed: {
    comunaRegiones() {
      return this.$store.getters["datosVariables/comunaRegiones"];
    },
    comunas() {
      return this.$store.getters["datosVariables/comunas"];
    },
    items_cdc() {
      return this.$store.getters["datosVariables/itemsCDC"];
    },
    items_mode_2() {
      return this.$store.getters["datosVariables/items_mode_2"];
    },
    items_mode_oth() {
      return this.$store.getters["datosVariables/items_mode_oth"];
    },
    patientVariables() {
      return this.$store.getters["patients/patientVariables"];
    },
  },
  async created() {
    const valores = await this.$store.dispatch("patients/loadVariablesData", {
      idPatient: this.$route.params["id"],
    });
    this.rut = valores.rut;
    if (this.$store.getters["patients/patientCD4"].length > 0) {
      const cd4_values = this.$store.getters["patients/patientCD4"][0];
      this.cd4_v = cd4_values.cd4_v;
      this.cd4_d = cd4_values.cd4_d;
    } else {
      this.loadCD4().then(() => {
        const cd4_values = this.$store.getters["patients/patientCD4"][0];
        this.cd4_v = cd4_values.cd4_v;
        this.cd4_d = cd4_values.cd4_d;
      });
    }
  },
};
</script>
