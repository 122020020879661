import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import vuetify from './plugins/vuetify'
import './plugins/apexChart'
import BaseCard from "./components/UI/BaseCard.vue";
import NavMenu from "./components/layouts/NavMenu.vue";
import SuccessMessage from "./components/UI/SuccessMessage.vue"
import BaseButton from './components/UI/BaseButton.vue'
import FooterBlock from './components/layouts/FooterBlock.vue'


Vue.config.productionTip = false

Vue.component('base-card',BaseCard)
Vue.component('nav-menu', NavMenu)
Vue.component('success-message',SuccessMessage)
Vue.component('base-button',BaseButton)
Vue.component('footer-block', FooterBlock)

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app')
