<template>
  <section v-if="!this.isLoading">
    <v-tabs fixed-tabs background-color="indigo" color="white" v-model="modelTab">
      <v-tab
        :exact="true"
        :to="{
          name: 'registrosCD4',
          params: { id: this.$route.params['id'] },
        }"
      >
        Registros CD4
      </v-tab>
      <v-tab
        :exact="true"
        :to="{
          name: 'registrosRNA',
          params: { id: this.$route.params['id'] },
        }"
      >
        Registros RNA
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </section>
  <section v-else>
    <v-row>
      <v-col align-self="center">
        <v-progress-circular indeterminate :size="50" color="primary" align></v-progress-circular>

        <h3>Cargando...</h3>
      </v-col>
    </v-row>
  </section>
</template>
<script>
export default {
  data() {
    return {
      modelTab: "/CVH0111221/laboratorio/registrosCD4",
      isLoading: false,
    };
  },
  methods: {
    async loadVariables() {
      await this.$store.dispatch("patients/loadVariablesData", {
        idPatient: this.$route.params["id"],
      });
    },
    Loading() {
      this.isLoading = true;
      // simulate AJAX
      setTimeout(() => {
        this.isLoading = false;
      }, 600);
    },
  },
  created() {
    this.Loading();
    this.loadVariables();
  },
};
</script>
