<template>
  <v-app>
    <v-container class="container-safe-area">
    <section>
      <v-snackbar v-model="dialog" :multi-line="multiLine" color="error">
        <p v-if="!message">
          Faltan respuestas en la encuesta. <br />
          Por favor llenar las respuestas que faltan
        </p>
        <span v-else>{{ message }}</span>

        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs" @click="dialog = false"> Cerrar </v-btn>
        </template>
      </v-snackbar>
      <p v-if="loading">HOLA</p>
      <!-- <v-form @submit.prevent="siguiente()" ref="form" v-if="!loading"> -->
      <v-form @submit.prevent="siguiente" ref="form">
        <v-col class="pa-0">
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientEncuesta.Encuesta_2"
                required
                :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              >
                <template v-slot:label
                  ><strong>
                    1. En el último año, ¿qué tan frecuentemente usaste algún tipo de droga (como por ejemplo:
                    marihuana, popper, coca, éxtasis, keta, G, etc) o alcohol para cambiar la forma en que te sentías?
                  </strong></template
                >
                <v-radio v-for="n in items_encuesta_1" :key="n" :label="`${n}`" :value="n"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientEncuesta.Encuesta_3"
                required
                :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              >
                <template v-slot:label
                  ><strong
                    >2. En el último año, ¿qué tan frecuentemente usaste más drogas o bebiste más alcohol del que habías
                    planeado?
                  </strong></template
                >
                <v-radio v-for="n in items_encuesta_1" required :key="n" :label="`${n}`" :value="n"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientEncuesta.Encuesta_4"
                required
                :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              >
                <template v-slot:label
                  ><strong
                    >3. En el último año, ¿qué tan frecuentemente sentiste que necesitabas o querías dejar de consumir
                    drogas o alcohol y no fuiste capaz?
                  </strong></template
                >
                <v-radio v-for="n in items_encuesta_1" :key="n" :label="`${n}`" :value="n"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientEncuesta.Encuesta_5"
                required
                :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              >
                <template v-slot:label
                  ><strong
                    >4. ¿En el último año, ¿Qué tan frecuentemente consumiste drogas o alcohol para ayudarte a sentirte
                    mejor?
                  </strong></template
                >
                <v-radio v-for="n in items_encuesta_1" :key="n" :label="`${n}`" :value="n"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientEncuesta.Encuesta_6"
                required
                :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              >
                <template v-slot:label
                  ><strong
                    >5. ¿Ha tenido problemas entre usted y su pareja, familiares, amigos o laborales a causa del consumo
                    de drogas o alcohol?
                  </strong></template
                >
                <v-radio v-for="n in items_encuesta_1" :key="n" :label="`${n}`" :value="n"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col> </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientEncuesta.Encuesta_7"
                required
                :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              >
                <template v-slot:label
                  ><strong
                    >6. ¿Qué tan a menudo ha sentido cada uno de los siguientes síntomas durante las cuatro últimas
                    semanas? ¿Poco interés o placer para hacer cosas?
                  </strong></template
                >
                <v-radio v-for="n in items_encuesta_2" :key="n" :label="`${n}`" :value="n"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientEncuesta.Encuesta_8"
                required
                :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              >
                <template v-slot:label
                  ><strong
                    >7. ¿Qué tan a menudo ha sentido cada uno de los siguientes síntomas durante las cuatro últimas
                    semanas? Se siente deprimido, irritado y sin esperanza ?</strong
                  ></template
                >
                <v-radio v-for="n in items_encuesta_2" :key="n" :label="`${n}`" :value="n"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientEncuesta.Encuesta_9"
                required
                :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              >
                <template v-slot:label
                  ><strong
                    >8. ¿Durante las últimas 4 semanas, ¿Con qué frecuencia su salud física o los problemas emocionales
                    han dificultado sus actividades laborales?</strong
                  ></template
                >
                <v-radio v-for="n in items_encuesta_2" :key="n" :label="`${n}`" :value="n"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientEncuesta.Encuesta_10"
                required
                :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              >
                <template v-slot:label
                  ><strong
                    >9. ¿Alguna vez algún médico o especialista le ha dicho que tiene depresión?
                  </strong></template
                >
                <v-radio
                  v-for="n in [
                    'Nunca.',
                    'Hace un año o menos.',
                    'Entre los últimos cinco años.',
                    'Hace más de 5 años.',
                  ]"
                  :key="n"
                  :label="`${n}`"
                  :value="n"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="patientEncuesta.Encuesta_11"
                required
                :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
              >
                <template v-slot:label
                  ><strong
                    >10. En tu vida cotidiana, ¿Cuán a menudo sientes que cuentas con alguna persona que te muestre amor
                    y afecto? (ejemplo: esposa/o, compañero/a hijos/as, amigos/as, etc).
                  </strong></template
                >
                <v-radio
                  v-for="n in [
                    'Ningún día.',
                    'Varios días.',
                    'Más de la mitad de los días.',
                    'Siempre o casi siempre.',
                  ]"
                  :key="n"
                  :label="`${n}`"
                  :value="n"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-radio-group
            v-model="patientEncuesta.Encuesta_1"
            required
            :rules="[(v) => !!v || 'Falta la respuesta a esta pregunta']"
          >
            <template v-slot:label><strong>11. Situación habitacional</strong></template>
            <v-radio v-for="n in items_habitacional" :key="n" :label="`${n}`" :value="n"></v-radio>
          </v-radio-group>
        </v-col>
        <v-btn type="submit">Siguiente</v-btn>
      </v-form>
      <!-- <div v-else>
      <v-progress-circular
        indeterminate
        :size="50"
        color="primary"
      ></v-progress-circular>
      <h3>Cargando...</h3>
    </div> -->
    </section>
    </v-container>
  </v-app>
</template>
<script>
export default {
  props: ["dataEncuesta"],
  data() {
    return {
      message: false,
      snackbar_color: null,
      snackbar: false,
      loading: false,
      dialog: false,
      multiLine: true,
      items_encuesta_1: [
        "Nunca.",
        "Menos de una vez al mes.",
        "Más de una vez al mes.",
        "Al menos una vez a la semana.",
        "Al menos una vez al día.",
      ],
      items_encuesta_2: ["Ningún día.", "Varios días.", "Más de la mitad de los días.", "Casi todos los días."],
      items_habitacional: [
        "Vivienda propia (arrendatario o propietario).",
        "Allegado.",
        "Vivienda colectiva (Albergue, residencia, pensión, etc.)",
      ],
    };
  },
  methods: {
    async siguiente() {
      const valid = this.$refs.form.validate();
      if (valid) {
        window.scrollTo(0, 0);
        //console.log(this.patientEncuesta);
        let response;
        if (this.patientEncuesta.id) {
          response = await this.$store.dispatch("patients/addEncuestaFast", {
            dataPatient: this.patientEncuesta,
            idPatient: this.$store.getters["patients/patient"].data.rut,
            method: "PUT",
          });
        } else {
          response = await this.$store.dispatch("patients/addEncuestaFast", {
            dataPatient: this.patientEncuesta,
            idPatient: this.$store.getters["patients/patient"].data.rut,
            method: "POST",
          });
        }
        let status;
        if (response.status) {
          status = response.status;
        } else {
          status = response.response.status;
        }
        if (status != 400) {
          this.$store.dispatch("patients/tabStatusAction", "variables");
          this.$router.push({
            name: "pacienteFastTrackVariables",
            params: { id: this.$route.params["id"] },
          });
        } else {
          console.log(response);
          this.dialog = true;
          this.message = response.response.data;
        }
      } else {
        this.dialog = true;
      }
    },
  },
  computed: {
    patientEncuesta() {
      return this.$store.getters["patients/patientEncuesta"];
    },
  },
};
</script>
