var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"container-safe-area"},[_c('div',[_c('h1',{staticClass:"text-center"},[_vm._v("Pacientes")]),_c('v-card',[_c('v-card-title',{staticClass:"text-center"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"text-center",attrs:{"server-items-length":_vm.lenServer,"footer-props":_vm.footerProps,"page":_vm.page,"headers":_vm.headers,"items":_vm.desserts,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"loading":_vm.isLoading,"loading-text":"Cargando, por favor espere"},on:{"update:page":function($event){_vm.page=$event},"pagination":_vm.changeSomewhere,"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:`item.codigoconasida`,fn:function({ item }){return [(_vm.permission === 'Medicos' || _vm.permission === 'Admin')?_c('router-link',{attrs:{"to":{
                name: 'paciente',
                params: {
                  id: item.codigoconasida,
                },
              }}},[_vm._v(_vm._s(item.codigoconasida))]):_c('p',[_vm._v(_vm._s(item.codigoconasida))])]}},{key:`item.edit`,fn:function({ item }){return [_c('v-btn',[_vm._v(" Editar ")]),_c('v-btn',{staticClass:"normal",on:{"click":function($event){return _vm.deletePatient(item.codigoconasida)}}},[_vm._v(" Eliminar ")])]}},{key:`item.fasttrack`,fn:function({ item }){return [_c('v-btn',{attrs:{"to":{
                name: 'pacienteFastTrack',
                params: { id: item.codigoconasida },
              }}},[_vm._v(" FastTrack ")]),_c('v-btn',{attrs:{"to":{
                name: 'subPredictores',
                params: { id: item.codigoconasida },
              }}},[_vm._v(" Sub-predictores ")])]}}],null,true)})],1),_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine,"color":_vm.snackbar_color},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"black","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[(_vm.errores)?_c('ul',_vm._l((_vm.errores),function(value,name){return _c('li',{key:value.id},[_vm._v(_vm._s(name)+": "+_vm._s(value))])}),0):_vm._e()])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }