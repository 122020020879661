import axios from "axios";
export default {
  async addPatient(context, dataFirst) {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    const data = dataFirst.dataPatient;
    var res = data.nacimiento.replace(/-/g, "").slice(2, 8);
    const codigoconasida =
      data.nombres[0] +
      data.apellido_paterno[0] +
      data.apellido_materno[0] +
      res.slice(4, 6) +
      res.slice(2, 4) +
      res.slice(0, 2) +
      data.rut.replace(/-/g, "").slice(-4);

    const dataPatient = {
      nombre: data.nombres,
      apellido_paterno: data.apellido_paterno,
      apellido_materno: data.apellido_materno,
      rut: data.rut,
      birth_d: data.nacimiento,
      date_derivacion: data.date_derivacion,
      unidad_derivacion: data.unidad_derivacion,
      codigoconasida: codigoconasida,
      nacionalidad: data.nacionalidad,
      otra_nacionalidad: data.otra_nacionalidad ? data.otra_nacionalidad : data.nacionalidad,
      previous_tar_treatment: data.previous_tar_treatment,
      register_date: currentDate,
    };

    return axios
      .post("/paciente/api/create", dataPatient)
      .then((response) => {
        if (response.status == 201) {
          console.log("Paciente agregado correctamente. Esta fue la data enviada: ", dataPatient);
          return response;
        }
      })
      .catch((error) => {
        if (!error["status"]) {
          error["status"] = 400;
        }
        return error;
      });
  },
  //***************************************************************************************************//
  async loadPatients(context /* payload */) {
    const response = await axios.get("paciente/api/list");
    const responseData = response.data.results;
    const patients = [];
    for (const key in responseData) {
      const patient = {
        codigoconasida: responseData[key].codigoconasida,
        nombres: responseData[key].nombre,
        primer_apellido: responseData[key].apellido_paterno,
        segundo_apellido: responseData[key].apellido_materno,
        rut: responseData[key].rut,
        nacimiento: responseData[key].birth_d,
        /*date_derivacion: responseData[key].dataPatient.date_derivacion,
        unidad_derivacion: responseData[key].dataPatient.unidad_derivacion, */
      };
      patients.push(patient);
    }
    context.commit("setPatients", patients);
  },
  //***************************************************************************************************//
  async loadEncuestaData(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/encuesta/detail/" + idPatient)
      .then((result) => {
        context.commit("setEncuestaPatient", result.data);
        return result.data;
      })
      .catch((error) => console.log(error));
  },
  cleanData(context /* payload */) {
    const data = null;
    context.commit("setCD4", data);
    context.commit("setEncuestaPatient", data);
    context.commit("setTabVariables", true);
    context.commit("setTabPrediction", true);
    context.commit("setTabModelo", true);
    context.commit("setPacienteIntervencional", data);
  },
  async deletePatient(context, payload) {
    const response = await fetch(
      `https://https-demo-vue-default-rtdb.firebaseio.com/patients/${payload.codigoConasida}.json`,
      {
        method: "DELETE",
      }
    );
    if (!response.ok) {
      //error management
    }

    context.commit("setPatients", payload.patients);
  },
  async setPatient(context, payload) {
    //
    let patients = context.getters["patients"];
    if (patients.length === 0) {
      await context.dispatch("loadPatients");
      patients = context.getters["patients"];
    } else if (patients.length > 0) {
      patients = context.getters["patients"];
    } else {
      console.log("Hubo un problema");
    }
    const value = patients.filter((patient) => {
      return patient.codigoconasida === payload;
    });
    context.commit("setPatient", value);
  },

  async addEncuestaFast(context, payload) {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${year}-${month}-${day}`;
    let dataPatientEncuesta = payload.dataPatient;

    dataPatientEncuesta = {
      ...dataPatientEncuesta,
      paciente_nombre: payload.idPatient,
      register_poll_date: currentDate,
    };
    if (payload.method === "POST") {
      return axios
        .post("/paciente/api/encuesta/create", dataPatientEncuesta)
        .then((result) => {
          context.commit("setEncuestaPatient", result.data);
          return result;
        })
        .catch((error) => {
          console.log("Error", error);
          return error;
        });
    } else if (payload.method === "PUT") {
      return axios
        .put("/paciente/api/encuesta/update/" + payload.dataPatient.id + "/", dataPatientEncuesta)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    }
  },
  async loadVariablesData(context, payload) {
    const idPatient = payload.idPatient;
    const result = await axios
      .get("paciente/api/detail/" + idPatient + "/")
      .then((result) => {
        context.commit("setVariable", result.data);
        //console.log(result.data);
        return result.data;
      })
      .catch((error) => console.log(error));
    return result;
  },
  async addVariablesFast(context, payload) {
    payload.dataPatient['mode_oth'] = "No responde"
    payload.dataPatient['ADQUISICION'] = 'SIN DATO'
    console.log(payload.dataPatient.Tiene_Autorizado)
    return await axios
      .put("paciente/api/update/" + payload.idPatient + "/", payload.dataPatient)
      .then((result) => {
        context.commit("setVariable", result.data);
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async getPatient(context, payload) {
    if (!payload) {
      console.log("Dispatching Undefined");
    }

    const response = await axios.get("/paciente/api/detail/" + payload + "/");

    context.commit("setPatient", response);
  },
  cleanPatient(context) {
    context.commit("setPatient", null);
  },

  async loadCD4Data(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/cd4/list/" + idPatient)
      .then((result) => {
        context.commit("setCD4", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setCD4", null);
      });
  },
  async loadCD4ListData(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/cd4/list/" + idPatient)
      .then((result) => {
        context.commit("setCD4", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setCD4", null);
      });
  },

  async addCD4(context, payload) {
    console.log("This is payload for adding cd4", payload);
    axios
      .post("paciente/api/cd4/create", payload)
      .then((response) => {
        context.dispatch("loadCD4ListData", { idPatient: response.data.paciente });
        console.log(response.data.paciente);
      })
      .catch((error) => console.log(error));
  },
  async editCD4(context, payload) {
    axios
      .put("paciente/api/cd4/update/" + payload.id + "/", payload)
      .then((response) => {
        context.dispatch("loadCD4ListData", { idPatient: response.data.paciente });
      })
      .catch((error) => console.log(error));
  },
  async deleteCD4(context, payload) {
    console.log(payload);
    axios
      .delete("paciente/api/cd4/delete/" + payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },
  /*----------------------------------Auditorias--------------------------------------------------------------------*/
  async loadAuditorias(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/auditoria/list/" + idPatient)
      .then((result) => {
        context.commit("setAuditorias", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setAuditorias", {});
      });
  },
  async editAuditorias(context, payload) {
    axios
      .put("paciente/api/auditoria/update/" + payload.id + "/", payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
    console.log(payload);
  },
  async addAuditorias(context, payload) {
    console.log("This is payload", payload);
    axios
      .post("paciente/api/auditoria/create", payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },

  /*---------Intervencional-------*/
  async loadIntervencional(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/intervencional/" + idPatient)
      .then((result) => {
        context.commit("setPacienteIntervencional", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPacienteIntervencional", {});
      });
  },

  async editIntervencional(context, payload) {
    return axios
      .put("paciente/api/intervencional/" + payload.paciente, payload)
      .then((result) => {
        return result;
      })
      .catch((error) => console.log(error));
  },
  async addIntervencional(context, payload) {
    console.log("This is payload", payload);
    return axios
      .post("paciente/api/intervencional/" + payload.paciente, payload)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log(error.response);
        return error.response;
      });
  },

  loadTARlist(context, payload) {
    return axios
      .get("/paciente/api/tar/list/" + payload.idPatient)
      .then((result) => {
        context.commit("setTAR", result.data);
        return result;
      })
      .catch((error) => {
        console.log(error);
        context.commit("setTAR", {});
      });
  },
  async editTAR(contex, payload) {
    axios
      .put("/paciente/api/tar/update/" + payload.id + "/", payload)
      .then((result) => console.log(result))
      .catch((error) => {
        console.log("Error", error);
      });
  },
  async addTAR(context, payload) {
    console.log("This is payload", payload);
    axios
      .post("paciente/api/tar/create", payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },
  /*-------------------------------------PAC-----------------------------------*/
  async loadPAC(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("/paciente/api/terapias_pac/list/" + idPatient)
      .then((result) => {
        context.commit("setPAC", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPAC", {});
      });
  },
  async addPAC(context, payload) {
    console.log(payload);
    await axios
      .post("/paciente/api/terapias_pac/create", payload)
      .then((result) => {
        context.commit("setPAC", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPAC", {});
      });
  },
  loadPACDetail(context, payload) {
    return axios
      .get("/paciente/api/terapias_pac/detail/" + payload)
      .then((result) => {
        console.log(result);
        context.commit("setTerapiaPAC", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setTerapiaPAC", {});
      });
  },
  async editPAC(context, payload) {
    console.log(payload);
    await axios
      .put("/paciente/api/terapias_pac/update/" + payload.id + "/", payload)
      .then((result) => {
        context.commit("setPAC", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPAC", {});
      });
  },
  async deleteTratamientoTAR(context, payload) {
    axios
      .delete("paciente/api/terapias_pac/delete/" + payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },

  /*-------------------------------------POP-----------------------------------*/
  async loadPOP(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("/paciente/api/terapias_pop/list/" + idPatient)
      .then((result) => {
        context.commit("setPOP", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPOP", {});
      });
  },
  async loadRNAData(context, payload) {
    const idPatient = payload.idPatient;
    await axios
      .get("paciente/api/rna/list/" + idPatient)
      .then((result) => {
        context.commit("setRNA", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setRNA", null);
      });
  },
  async addRNA(context, payload) {
    axios
      .post("paciente/api/rna/create", payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },
  async editRNA(context, payload) {
    console.log(payload.id);
    axios
      .put("paciente/api/rna/update/" + payload.id + "/", payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },
  async deleteRNA(context, payload) {
    console.log(payload);
    axios
      .delete("paciente/api/rna/delete/" + payload)
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  },
  /*----------------------------------PREDICTORES-------------------------------------*/
  predictEcosistema(context, payload) {
    return axios
      .post("paciente/api/allin/", { rut: payload.rut, year: payload.year, predictor: payload.predictor })
      .then((result) => {
        console.log("result", result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return error.response;
      });
  },
  predictDepresion(context, payload) {
    return axios
      .post("paciente/api/allin/", { rut: payload.rut, year: payload.year, predictor: payload.predictor })
      .then((result) => {
        console.log("result", result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return error.response;
      });
  },
  predictAyD(context, payload) {
    return axios
      .post("paciente/api/allin/", { rut: payload.rut, year: payload.year, predictor: payload.predictor })
      .then((result) => {
        console.log("result", result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return error.response;
      });
  },
  predictFarmacia(context, payload) {
    return axios
      .post("paciente/api/allin/", { rut: payload.rut, year: payload.year, predictor: payload.predictor })
      .then((result) => {
        console.log("result", result);
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return error.response;
      });
  },
  loadPredictions(context, payload) {
    return axios
      .get("/paciente/api/probabilidad/" + payload)
      .then((result) => {
        //console.log("This are the predictions", result);
        context.commit("setPredictions", result.data);
        return result.data;
      })
      .catch(() => {
        context.commit("setPredictions", {});
      });
  },
  /*----------------------------------DROP-------------------------------------*/
  async loadDrop(context, payload) {
    /* axios.get("paciente/api/drop/list/"+paciente) */
    context.commit("setDrop", { hola: payload });
  },

  /*---------------------------TABS----------------------------*/
  tabStatusAction(context, payload) {
    if (payload == "variables") {
      context.commit("setTabVariables", false);
    } else if (payload == "prediction") {
      context.commit("setTabPrediction", false);
    } else if (payload == "modelo") {
      context.commit("setTabModelo", false);
    }
  },
  tabStatusActionTrue(context, payload) {
    if (payload == "prediction") {
      context.commit("setTabPrediction", true);
    } else if (payload == "variables") {
      context.commit("setTabVariables", true);
    }
  },
  statusForm(context, payload) {
    console.log(payload);
    context.commit("setStatusForm", payload);
  },
};
