<template>
  <v-app>
    <v-container class="container-safe-area">
      <v-card class="pa-4">
          <h1>Primera visita</h1>
          <v-form @submit.prevent="submitData">
            <v-row>
              <v-col>
                <v-text-field v-model="nombres" label="Nombres" required> </v-text-field>
                <v-text-field v-model="primer_apellido" label="Primer Apellido" required> </v-text-field>
                <v-text-field v-model="segundo_apellido" label="Segundo Apellido" required> </v-text-field>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="nacionalidad"
                      label="Nacionalidad"
                      :items="items_nacionalidad"
                      item-text="descripcion"
                      item-value="mode"
                      return-object
                      single-line
                      required
                      :search-input.sync="searchInput"
                      :debounce-search="0"
                      hint="Nacionalidad"
                      :rules="[(v) => !!v || 'Falta nacionalidad']"
                    >
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-if="nacionalidad === 'Otra'"
                      v-model="otra_nacionalidad"
                      label="Otra nacionalidad"
                      hint="Otra nacionalidad"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!--   <v-text-field
                  v-model="rut"
                  label="Rut"
                  required
                  :rules="rutRules"
                > -->
                <v-text-field
                  v-if="(this.nacionalidad == 'Chilena') | (this.nacionalidad == null)"
                  v-model="rut"
                  label="Rut"
                  required
                >
                </v-text-field>
                <v-text-field v-else v-model="identificacion" label="Rut Provisorio" required> </v-text-field>

                <div data-app class="menu_date">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="nacimiento"
                        label="Fecha de nacimiento"
                        prepend-icon="mdi-calendar"
                        messages="Apretar en el año para ir a un año en especifico, lo mismo para mes"
                        readonly
                        no-title
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="nacimiento"
                      v.model:active-picker="activePicker"
                      :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="save('menu')"
                      color="indigo darken-2"
                    >
                    </v-date-picker
                  ></v-menu>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2>Derivado de otro centro</h2>
                <div data-app>
                  <v-menu
                    ref="menu_derivacion"
                    v-model="menu_derivacion"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_derivacion"
                        label="Fecha de derivacion"
                        prepend-icon="mdi-calendar"
                        messages="Apretar en el año para ir a un año en especifico, lo mismo para mes"
                        readonly
                        no-title
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_derivacion"
                      v.model:active-picker="activePicker"
                      :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="save('menu_derivacion')"
                      color="indigo darken-2"
                    >
                    </v-date-picker
                  ></v-menu>
                </div> </v-col
              ><v-col>
                <v-text-field class="derivacion" v-model="unidad_derivacion" label="Nombre unidad de derivación" required>
                </v-text-field>
              </v-col>
            </v-row>

            <v-radio-group
              v-model="previous_tar_treatment"
              required
              :rules="[(v) => !!v || 'Falta tratamiento previo']"
              row
            >
              <template v-slot:label>
                <strong>¿Ha tenido tratamiento TAR?</strong>
              </template>
              <v-radio label="Sí" value="1"></v-radio>
              <v-radio label="No" value="0"></v-radio>
            </v-radio-group>
            <v-divider style="margin: 15px" inset></v-divider>

          <v-btn width="200px" height="50px" type="submit" :disabled="enabled">Guardar</v-btn>
        </v-form>
        <v-snackbar v-model="snackbar" :multi-line="multiLine" :color="snackbar_color">
          <ul v-if="errores">
            <li v-for="(value, name) in errores" :key="value.id">{{ name }}: {{ value }}</li>
          </ul>
          <span v-else>{{ mensaje }}</span>

            <template v-slot:action="{ attrs }">
              <v-btn color="black" text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
            </template>
          </v-snackbar>
      </v-card>
    </v-container>
  </v-app>  
</template>
<script>
export default {
  data() {
    return {
      mensaje: null,
      multiLine: true,
      snackbar: false,
      text: `I'm a multi-line snackbar.`,
      errores: [],
      nombres: "",
      primer_apellido: "",
      segundo_apellido: "",
      rut: null,
      identificacion: "",
      nacimiento: "",
      date_derivacion: "",
      unidad_derivacion: "",
      menu: null,
      menu_derivacion: null,
      enabled: false,
      snackbar_color: null,
      nacionalidad: null,
      searchInput: "",
      previous_tar_treatment: "0",
      items_nacionalidad: [
        "Argentina",
        "Boliviana",
        "Brasileña",
        "Chilena",
        "Colombiana",
        "Cubana",
        "Dominicana",
        "Ecuatoriana",
        "Española",
        "Haitiana",
        "Hondureña",
        "Mexicana",
        "Nicaraguense",
        "Nigeriano",
        "Panameña",
        "Paraguaya",
        "Peruana",
        "Salvadoreña",
        "Uruguaya",
        "Venezolana",
        "Otra",
      ],
    };
  },
  methods: {
    save(calendar, date) {
      this.$refs[calendar].save(date);
    },
    getDV(T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
    async submitData() {
      this.errores = null;
      this.enabled = true;
      const values = {
        nombres: this.nombres,
        apellido_paterno: this.primer_apellido,
        apellido_materno: this.segundo_apellido,
        rut: this.rut ? this.rut : this.identificacion,
        nacimiento: this.nacimiento,
        date_derivacion: this.date_derivacion,
        unidad_derivacion: this.unidad_derivacion,
        nacionalidad: this.nacionalidad,
        otra_nacionalidad: this.otra_nacionalidad,
        previous_tar_treatment: this.previous_tar_treatment,
      };
      await this.$store
        .dispatch("patients/addPatient", {
          dataPatient: values,
        })
        .then((response) => {
          let status_aux = null
          if(response.response){
            status_aux = response.response.status
          }else{
            status_aux = null
          }
          if (response.status == 400 || status_aux==400) {
            this.enabled = false;
            this.snackbar_color = "error";
            this.snackbar = true;
            if (response.response.data) {
              this.errores = response.response.data
            } else {
              this.errores = { Error: response.message };
            }
          }
          if (response.status == 201) {
            this.snackbar = true;
            this.mensaje = "Agregando paciente";
            this.snackbar_color = "success";
            this.$router.push("/pacientes");
          }
        });
    },
  },
  computed: {
    rutRules() {
      const rutRules = [];
      if (this.rut) {
        //Validación de formato
        var regexp = "^[0-9]+-[0-9kK]{1}$";
        if (!this.rut.match(regexp)) {
          rutRules.push(
            "El formato del RUT ingresado es incorrecto. No debe contener puntos, debe contener guión y dígito verificador."
          );
        } else {
          //Validación de DV
          var rut_numbers = this.rut.split("-")[0];
          var rut_dv = this.rut.split("-")[1];

          var calc_dv = this.getDV(rut_numbers);

          if (calc_dv == "k") {
            if (calc_dv != rut_dv.toLowerCase()) {
              rutRules.push("El RUT ingresado no es válido.");
            }
          } else {
            if (calc_dv != rut_dv) {
              rutRules.push("El RUT ingresado no es válido.");
            }
          }
        }
      }
      return rutRules;
    },
  },
};
</script>

<style scoped>
.derivacion {
  margin-top: 40px;
}
</style>
