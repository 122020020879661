<template>
  <section>
    <v-data-table :headers="headers" :items="desserts" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Mediciones RNA</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nueva Medición RNA
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            required
                            :rules="[(v) => !!v || 'Falta fecha de nacimiento']"
                            v-model="editedItem.rna_d"
                            label="Fecha de registro"
                            prepend-icon="mdi-calendar"
                            messages="Apretar en el año para ir a un año en especifico, lo mismo para mes"
                            readonly
                            no-title
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.rna_d"
                          v.model:active-picker="activePicker"
                          :max="
                            new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          min="1950-01-01"
                          color="indigo darken-2"
                        >
                        </v-date-picker
                      ></v-menu>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.rna_v"
                        label="Medida RNA"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="text-h7"
                >Estas seguro que quieres eliminar esta medición?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >SI</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  data() {
    return {
      rut: false,
      menu: false,
      headers: [
        { text: "Fecha", value: "rna_d" },
        { text: "Medición RNA", value: "rna_v" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      dialog: false,
      editedItem: {
        id: null,
        rna_d: null,
        rna_v: null,
      },
      dialogDelete: false,
    };
  },
  methods: {
    async loadRNAData() {
      await this.$store.dispatch("patients/loadRNAData", {
        idPatient: this.rut,
      });
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save() {
      if (this.editedIndex > -1) {
        this.$store.dispatch("patients/editRNA", {
          ...this.editedItem,
          paciente: this.rut,
        });
        Object.assign(this.desserts[this.editedIndex], {
          ...this.editedItem,
          paciente: this.rut,
        });
      } else {
        this.$store.dispatch("patients/addRNA", {
          ...this.editedItem,
          paciente: this.rut,
        });
        this.desserts.push(this.editedItem);
      }

      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteItem(item) {
      await this.$store.dispatch("patients/deleteRNA", item.id);

      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      await this.loadRNAData();
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async loadVariables() {
      const valores = await this.$store.dispatch("patients/loadVariablesData", {
        idPatient: this.$route.params["id"],
      });
      this.rut = valores.rut;
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva medición" : "Editar medición";
    },

    desserts() {
      return this.$store.getters["patients/patientRNA"].results;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    await this.loadVariables().then(() => {
      this.loadRNAData();
    });
  },
};
</script>
