var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.isLoading)?_c('section',[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"indigo","color":"white"},model:{value:(_vm.modelTab),callback:function ($$v) {_vm.modelTab=$$v},expression:"modelTab"}},[_c('v-tab',{attrs:{"to":{
        name: 'tratamientoTAR',
        params: { id: this.$route.params['id'] },
      }}},[_vm._v(" Tratamiento TAR ")]),_c('v-tab',{attrs:{"exact":true,"to":{
        name: 'tratamientoPOP',
        params: { id: this.$route.params['id'] },
      }}},[_vm._v(" Tratamiento POP ")])],1),_c('router-view')],1):_c('section',[_c('v-row',[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":50,"color":"primary","align":""}}),_c('h3',[_vm._v("Cargando...")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }