<template>
  <section>
    <v-form @submit.prevent="submitData()">
      <h1>Agregar Tratamiento Antiretroviral</h1>
      <v-container>
        <h3>Tratamiento</h3>
        <p>Por implementar</p>
    <!--     <v-row> -->
          <!--Fechas inicio y fin tratamiento-->
         <!-- <v-col>
            <div data-app>
              <v-menu
                v-model="menu"
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="TAR.Fecha_accion"
                    label="Fecha inicio tratamiento"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="TAR.Fecha_accion"
                  v.model:active-picker="activePicker"
                  no-title
                  :max="
                    new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                  @change="save()"
                ></v-date-picker
              ></v-menu>
            </div>
          </v-col>
          <v-col>
            <div data-app>
              <v-menu
                v-model="menu_fin"
                ref="menu_fin"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="TAR.Fecha_fin"
                    label="Fecha finalización tratamiento"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="TAR.Fecha_fin"
                  v.model:active-picker="activePicker"
                  no-title
                  min="1950-01-01"
                  @change="save()"
                ></v-date-picker
              ></v-menu>
            </div>
          </v-col>
        </v-row>

        <v-divider vertical></v-divider>
        <h3>Drogas utilizadas</h3>
        <v-row class="mb-10">!-->
          <!--Drogas Utilizada
          <v-col
            >-->
            <!--Droga 1-->
            <!-- <v-select
              v-model="TAR.droga_1"
              :items="drogas"
              label="Droga 1"
              return-object
              single-line
            ></v-select> -->
           <!--  <v-text-field v-model="TAR.droga_1" label="Droga 1" required> </v-text-field>
            <v-select
              v-model="TAR.Pslg_1"
              :items="posologia_pac"
              label="Posología droga 1"
              return-object
              single-line
            >
            </v-select>
          </v-col>
          <v-col
            > --><!--Droga 2-->
            <!-- <v-select
              v-model="TAR.droga_2"
              :items="drogas"
              label="Droga 2"
              return-object
              single-line
            ></v-select> -->
           <!--  <v-text-field v-model="TAR.droga_2" label="Droga 2" required> </v-text-field>
            <v-select
              v-model="TAR.Pslg_2"
              :items="posologia_pac"
              label="Posología droga 2"
              return-object
              single-line
            >
            </v-select>
          </v-col>
          <v-col
            > --><!--Droga 3-->
            <!-- <v-select
              v-model="TAR.droga_3"
              :items="drogas"
              label="Droga 3"
              return-object
              single-line
            ></v-select> -->
            <!-- <v-text-field v-model="TAR.droga_3" label="Droga 3" required> </v-text-field>
            <v-select
              v-model="TAR.Pslg_3"
              :items="posologia_pac"
              label="Posología droga 3"
              return-object
              single-line
            >
            </v-select>
          </v-col>
          <v-col
            > --><!--Droga 4-->
            <!-- <v-select
              v-model="TAR.droga_4"
              :items="drogas"
              label="Droga 4"
              return-object
              single-line
            ></v-select> -->
           <!--  <v-text-field v-model="TAR.droga_4" label="Droga 4" required> </v-text-field>
            <v-select
              v-model="TAR.Pslg_4"
              :items="posologia_pac"
              label="Posología droga 4"
              return-object
              single-line
            >
            </v-select>
          </v-col>
        </v-row>
        <h3>Acciones</h3>
        <v-row>
          <v-col
            ><v-radio-group v-model="TAR.accion">
              <template v-slot:label><strong>Acciones</strong></template>
              <v-radio
                v-for="n in [
                  'Cambio',
                  'Inicio',
                  'Continuacion',
                  'Reinicio',
                  'Reinicio/cambio',
                  'Continuacion/cambio',
                ]"
                :key="n"
                :label="`${n}`"
                :value="n"
              ></v-radio> </v-radio-group
          ></v-col>
          <v-col>
            <v-row>
              <v-select
                v-model="TAR.motivo"
                :items="[
                  'SIMPLIFICACION',
                  'NAIVE',
                  'RAM',
                  'STOCK',
                  'ABANDONO',
                  'TRASLADO',
                  'EXTRANJERO',
                  'FRACASO VIROLOGICO',
                  'UPGRADE',
                  'ISAPRE',
                  'PROTOCOLO',
                  'INTERACCION',
                  'IR',
                  'CONTRAINDICACION',
                  'AJUSTE DOSIS',
                  'TBC',
                  'OTRO',
                  'MALA ADHERENCIA',
                  'EMBARAZO',
                  'TBC TERMINO',
                  'VHB',
                  'REINICIO',
                  'CONTINUACION',
                  'CONTRAINDICACIÓN',
                  'FRACASO INMUNOLOGICO',
                  'HOSPITALIZACION',
                  'Refuerzo mala adherencia',
                ]"
                label="Motivo de la acción"
                return-object
                single-line
                hint="Motivo"
                persistent-hint
              >
              </v-select>
            </v-row>
          </v-col>
        </v-row>
        <h3>Medicamentos</h3>
        <v-row>
          <v-col>
            <div data-app>
              <v-menu
                v-model="menu_entrega"
                ref="menu_entrega"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="TAR_drugs.fecha_entrega"
                    label="Fecha entrega medicamentos"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="TAR_drugs.fecha_entrega"
                  v.model:active-picker="activePicker"
                  no-title
                  :max="
                    new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                  @change="save('menu_entrega')"
                ></v-date-picker
              ></v-menu>
            </div>
          </v-col>
          <v-col>
            <div data-app>
              <v-menu
                v-model="menu_retiro"
                ref="menu_retiro"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="TAR_drugs.fecha_retiro"
                    label="Fecha de retiro medicamentos"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="TAR_drugs.fecha_retiro"
                  v.model:active-picker="activePicker"
                  no-title
                  :max="
                    new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1950-01-01"
                  @change="save('menu_retiro')"
                ></v-date-picker
              ></v-menu>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><v-radio-group v-model="Tiene_Autorizado">
              <template v-slot:label>
                <strong>Tiene a alguien autorizado para medicamentos?</strong>
              </template>
              <v-radio
                v-for="n in ['Si', 'No', 'Sin dato']"
                :key="n"
                :label="`${n}`"
                :value="n"
              ></v-radio> </v-radio-group
          ></v-col>
          <v-col v-if="Tiene_Autorizado === 'Si'"
            ><v-radio-group v-model="TAR_drugs.retira_tutor">
              <template v-slot:label>
                <strong>Retira Tutor</strong>
              </template>
              <v-radio
                v-for="n in ['Si', 'No', 'Sin dato']"
                :key="n"
                :label="`${n}`"
                :value="n"
              ></v-radio> </v-radio-group
          ></v-col>

          <v-col
            ><v-radio-group v-model="TAR_drugs.situacion_varios">
              <template v-slot:label>
                <strong>Situación varios (Estado del TAR)</strong>
              </template>
              <v-radio
                v-for="n in ['Activo', 'Abandono']"
                :key="n"
                :label="`${n}`"
                :value="n"
              ></v-radio> </v-radio-group
          ></v-col>
        </v-row> -->
      </v-container>
     <!--  <v-btn class="normal" type="submit"> Guardar datos </v-btn> -->
    </v-form>
  </section>
</template>

<script>
export default {
  props: ["idTAR"],
  data() {
    return {
      menu: false,
      menu_fin: false,
      menu_inicio: false,
      menu_retiro: false,
      menu_entrega: false,
      TAR: {
        Fecha_accion: null,
        fechafin: null,
        Fecha_fin: null,
        motivo: null,
        droga_1: null,
        droga_2: null,
        droga_3: null,
        droga_4: null,
        Pslg_1: null,
        Pslg_2: null,
        Pslg_3: null,
        Pslg_4: null,
      },
      TAR_drugs: {
        fecha_retiro: null,
        fecha_entrega: null,
        situacion_varios: null,
      },
      Tiene_Autorizado: null,
    };
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    submitData() {
      if (this.idTAR) {
        this.$store.dispatch("patients/editPAC", { ...this.TAR, id: this.idTAR });
      } else {
        this.$store.dispatch("patients/addPAC", {
          ...this.TAR,
          paciente: this.patientVariables.rut,
        });
      }
    },
    async loadVariables() {
      await this.$store.dispatch("patients/loadVariablesData", {
        idPatient: this.$route.params["id"],
      });
    },
    /* async loadTARlist() {
      await this.$store.dispatch("patients/loadTARlist", this.idTAR);
    }, */
  },
  computed: {
    patientVariables() {
      return this.$store.getters["patients/patientVariables"];
    },
    posologia_pac() {
      return this.$store.getters["datosVariables/posologia_pac"];
    },
    terapia_pac() {
      return this.$store.getters["patients/patientTerapiaPAC"];
    },
  },
  async created() {
    /* this.$store.dispatch("patients/loadPACDetail", this.$route.params["idTAR"]).then((res) => {
      this.TAR = res;
    }); */
   /*  this.$store.dispatch("patients/loadTARlist", this.idTAR).then((res)=>console.log(res)) */
  },
};
</script>
