var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Tratamientos Antiretroviral")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","exact":true,"to":{
              name: 'addTAR',
              params: { idTAR: 1},
            }}},[_vm._v(" Nuevo Tratamiento Antiretroviral ")]),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h7"},[_vm._v("Estas seguro que quieres eliminar esta medición?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("SI")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:`item.id`,fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                name: 'addTAR',
                params: {
                  idTAR: item.id,
                },
              }}},[_vm._v(_vm._s(item.id))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editTAR(item.id)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }