<template>
  <section>
    <v-data-table :headers="headers" :items="desserts" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Tratamientos Antiretroviral</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn
          color="primary"
          dark
          class="mb-2"
              :exact="true"
              :to="{
                name: 'addTAR',
                params: { idTAR: 1},
              }"
            >
            Nuevo Tratamiento Antiretroviral
          </v-btn>
          

          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="text-h7"
                >Estas seguro que quieres eliminar esta medición?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >SI</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
       <template v-slot:[`item.id`]="{ item }">
              <router-link
                
                :to="{
                  name: 'addTAR',
                  params: {
                    idTAR: item.id,
                  },
                }"
                >{{ item.id }}</router-link
              >
              
            </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editTAR(item.id)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
      headers: [
        { text: "Identificación Terapia", value: "id" },
        { text: "Fecha Inicio", value: "Fecha_accion" },
        { text: "Accion", value: "accion" },
        { text: "Motivo", value: "motivo" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      dialog: false,
      editedItem: {
        cd4_d: null,
        cd4_v: null,
        id: null,
      },
      dialogDelete: false,
    };
  },
  methods: {
    editTAR(id){
        this.$router.push({name:"addTAR",params:{idTAR:id}})
    },
  
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save() {
      if (this.editedIndex > -1) {
        this.$store.dispatch("patients/editCD4", {
          ...this.editedItem,
          paciente: this.patientVariables.rut,
        });
        Object.assign(this.desserts[this.editedIndex], {
          ...this.editedItem,
          paciente: this.patientVariables.rut,
        });
      } else {
        this.$store.dispatch("patients/addCD4", {
          ...this.editedItem,
          paciente: this.patientVariables.rut,
        });
        this.desserts.push(this.editedItem);
      }

      this.close();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteItem(item) {
      await this.$store.dispatch("patients/deleteTratamientoTAR", item.id);

      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      await this.loadPAC();
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva medición" : "Editar medición";
    },

    desserts() {
        console.log( this.$store.getters["patients/patientPAC"].results)
      return this.$store.getters["patients/patientPAC"].results;
    },
    patientVariables() {
      return this.$store.getters["patients/patientVariables"];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  
};
</script>
